import React from 'react';
import './App.css';
import HomePage from './components/HomePageFolder/HomePage';

function App(): JSX.Element {
  return (
    <>
      <HomePage />
    </>
  );
}

export default App;
